<script>
import { fetchAuthorYearlyCitations, fetchSuggestedAuthors } from '@/api'
import { Action } from 'epmc-patterns/components/v2'

// only AUTHORID:xxx works
const getSearchTerm = (query) => {
  let term = ''
  if (query) {
    const parts = query.split(':')
    if (parts.length === 2 && parts[0] === 'AUTHORID') {
      term = parts[1]
    }
  }
  return term
}

export default {
  components: { Action },
  props: {
    query: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      authorInfo: null,
      citationCount: 0,
    }
  },
  watch: {
    query() {
      this.getSuggestedAuthors()
    },
  },
  created() {
    this.getSuggestedAuthors()
  },
  methods: {
    getSuggestedAuthors() {
      const term = getSearchTerm(this.query)
      if (term) {
        fetchSuggestedAuthors(term).then((response) => {
          if (response) {
            this.authorInfo = response[0]
            if (response[0] && response[0].totalPublications) {
              fetchAuthorYearlyCitations(response[0].orcidId).then(
                (response2) => {
                  this.citationCount = response2.reduce(
                    (accumulator, currentValue) =>
                      accumulator + currentValue.citingArticlesCount,
                    0
                  )
                }
              )
            }
          }
        })
      } else {
        this.authorInfo = null
        this.citationCount = 0
      }
    },
  },
}
</script>
<template>
  <div>
    <div
      v-if="authorInfo"
      id="search-results--author-info"
      class="search-info-box"
    >
      <div class="matched-author">
        <h2>Results for {{ authorInfo.displayValue || authorInfo.orcidId }}</h2>
        <div>
          <action
            :to="{
              name: 'authorProfile',
              params: { orcid: authorInfo.orcidId.toUpperCase() },
            }"
          >
            <i slot="icon" class="fa fa-user" />View profile
          </action>
        </div>
      </div>
      <hr />
      <div class="matched-author small">
        <div>
          <i class="fab fa-orcid" />
          {{ authorInfo.orcidId.toUpperCase() }}
          <br />
          {{ authorInfo.affiliation || 'No affiliation detected' }}
        </div>
        <div>
          {{
            authorInfo.totalPublications
              ? authorInfo.totalPublications.toLocaleString()
              : 0
          }}<br class="remove" />
          Publication{{ authorInfo.totalPublications !== 1 ? 's' : '' }}
        </div>
        <div>
          {{ citationCount.toLocaleString() }}<br class="remove" />
          Citation{{ citationCount !== 1 ? 's' : '' }}
        </div>
      </div>
      <hr />
    </div>
    <h2 v-else class="alt-text">Search results</h2>
  </div>
</template>
<style lang="scss">
#search-results--author-info {
  h2 {
    line-height: $base-unit * 7;
    font-size: $base-unit * 5;
    margin: 0;
  }
  hr {
    margin: ($base-unit * 2) 0;
  }
  .matched-author {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    div {
      flex-shrink: 0;
      padding: 0 ($base-unit * 2);
      text-align: right;
      &:first-child {
        text-align: left;
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
    @media screen and (max-width: $breakpoint-small) {
      flex-wrap: wrap;
      div:last-child {
        padding: 0;
        margin-top: $base-unit;
      }
    }
    @media screen and (max-width: $breakpoint-smallest) {
      div {
        padding: 0;
        text-align: left;
        margin: $base-unit 0;
        &:first-child {
          width: 100%;
        }
        .remove {
          display: none;
        }
      }
    }
  }
}
</style>
