var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.suggestedAuthors.length)?_c('div',{attrs:{"id":"search-results--sugAuthor--panel"}},[_c('h3',{staticClass:"title"},[_vm._v("\n    Suggested authors matching \""+_vm._s(_vm.query.replace(/['"]/g, ''))+"\"\n    with an ORCID\n    "),_c('tooltip',{attrs:{"boundaries":"#search-page-content"}},[_c('i',{staticClass:"far fa-question-circle",attrs:{"slot":"trigger"},slot:"trigger"}),_vm._v("\n      Suggested authors have linked their articles in Europe PMC to their\n      ORCID iD. Up to two suggested authors with a matching surname and\n      initial are shown, ordered by the total number of publications they have\n      in Europe PMC.\n    ")])],1),_c('list',{attrs:{"list":_vm.suggestedAuthors,"separator-above":"","separator-below":""},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return _c('div',{key:item.orcidId,staticClass:"suggested-author small"},[_c('div',[(item.displayValue)?_c('action',{attrs:{"id":'search-results--sugAuthor--name-' + item.orcidId,"to":{
            name: 'search',
            query: { query: 'AUTHORID:' + item.orcidId.toUpperCase() },
          }},on:{"click":function($event){return _vm.registerMatomoEventForAuthorSuggestion('Author name')}}},[_c('i',{staticClass:"fas fa-search search-icon",attrs:{"slot":"icon"},slot:"icon"}),_vm._v(_vm._s(item.displayValue)+"\n        ")]):_vm._e(),_c('br'),_c('span',{staticClass:"suggested-author-affiliation"},[_vm._v("\n          "+_vm._s(item.affiliation ? item.affiliation : 'No affiliation detected')+"\n        ")])],1),_c('div',[_vm._v("\n        "+_vm._s(item.totalPublications
            ? item.totalPublications.toLocaleString()
            : 0)),_c('br',{staticClass:"remove"}),_vm._v("\n        Publication"+_vm._s(item.totalPublications !== '1' ? 's' : '')+"\n      ")]),_c('div',[_vm._v("\n        "+_vm._s(item.citationCount ? item.citationCount.toLocaleString() : 0)),_c('br',{staticClass:"remove"}),_vm._v("\n        Citation"+_vm._s(item.citationCount !== 1 ? 's' : '')+"\n      ")]),_c('div',[_c('router-link',{attrs:{"id":'search-results--sugAuthor--ViewLink-' + item.orcidId,"to":{
            name: 'authorProfile',
            params: { orcid: item.orcidId.toUpperCase() },
          }},on:{"click":function($event){return _vm.registerMatomoEventForAuthorSuggestion('Author profile')}}},[_vm._v("View profile")])],1)])}}],null,false,885632557)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }