<script>
import { fetchSuggestions } from '@/api'
import { replaceUrlQuery } from 'epmc-patterns/helpers'

const hasBooleanOp = (str) => {
  const HAS_BOOLEAN_OPERATOR = /\s+OR|AND|NOT\s+\S+:\s*\S+/i
  return HAS_BOOLEAN_OPERATOR.test(str)
}

const hasNum = (str) => {
  const HAS_NUM = /[0-9]+/i
  return HAS_NUM.test(str)
}

const hasField = (str) => {
  const HAS_FIELD = /\S+:\S+/i
  return HAS_FIELD.test(str)
}

export default {
  props: {
    query: {
      type: String,
      default: '',
    },
    hitCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      topSuggestion: null,
    }
  },
  watch: {
    // two props cause getSuggestions to be called twice; but this is an small issue
    query() {
      this.getSuggestions()
    },
    hitCount() {
      this.getSuggestions()
    },
  },
  created() {
    this.getSuggestions()
  },
  methods: {
    getSuggestions() {
      const { query, hitCount } = this
      fetchSuggestions(query).then((suggestions) => {
        if (suggestions) {
          const popularSuggestion = suggestions
            .filter((suggestion) => !hasField(suggestion.collationQueryString))
            .sort((s1, s2) => s2.numberOfHits - s1.numberOfHits)[0]
          this.topSuggestion =
            popularSuggestion && popularSuggestion.numberOfHits > hitCount
              ? popularSuggestion
              : null
          if (
            hitCount === 0 &&
            this.topSuggestion &&
            !hasBooleanOp(query) &&
            !hasNum(query) &&
            !hasField(query)
          ) {
            this.$router.push({
              path:
                'search' +
                replaceUrlQuery({
                  query: this.topSuggestion.collationQueryString,
                  previousQuery: this.query,
                  page: 1,
                  sortBy: '',
                  maxPageJump: '',
                }),
            })
          }
        }
      })
    },
  },
}
</script>
<template>
  <div
    v-if="topSuggestion && !$route.query.previousQuery"
    id="search-results--didYouMean--text"
    class="italic"
  >
    Did you mean:
    <router-link
      id="search-results--didYouMean--link"
      :to="{
        name: 'search',
        query: { query: topSuggestion.collationQueryString },
      }"
      class="semi-bold"
      >{{ topSuggestion.collationQueryString }}</router-link
    >
    ({{ topSuggestion.numberOfHits.toLocaleString() }} results)?
  </div>
</template>
