<script>
import { fetchAuthorYearlyCitations, fetchSuggestedAuthors } from '@/api'
import { registerMatomoEvent } from '@/helpers/matomo'
import { Action, List, Tooltip } from 'epmc-patterns/components/v2'

// 1. AUTH:xxx; 2. AUTHORID:xxx; 3. other
const getSearchTerm = (query) => {
  let term = query
  if (query) {
    const parts = query.split(':')
    if (parts.length === 2 && parts[0] === 'AUTH') {
      term = parts[1].replace(/"/g, '')
    } else {
      const ORCID_SEARCH_PATTERN =
        'AUTHORID:"(\\d{4}-\\d{4}-\\d{4}-\\d{3}[\\d|X])"'
      const res = query.match(ORCID_SEARCH_PATTERN)
      if (res) {
        term = res[1]
      }
    }
  }
  return term
}

export default {
  components: { Action, List, Tooltip },
  props: {
    query: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      suggestedAuthors: [],
    }
  },
  watch: {
    async query() {
      await this.getSuggestedAuthors()
    },
  },
  async created() {
    await this.getSuggestedAuthors()
  },
  methods: {
    async getSuggestedAuthors() {
      const term = getSearchTerm(this.query)
      if (term) {
        const response = await this.getSuggestedAuthorsData(term)
        if (response) {
          this.suggestedAuthors = response
          response.forEach((suggestion, index) => {
            fetchAuthorYearlyCitations(suggestion.orcidId).then((response2) => {
              if (response2) {
                this.$set(
                  this.suggestedAuthors[index],
                  'citationCount',
                  response2.reduce(
                    (accumulator, currentValue) =>
                      accumulator + currentValue.citingArticlesCount,
                    0
                  )
                )
              }
            })
          })
        }
      }
    },
    getSuggestedAuthorsData(term) {
      return fetchSuggestedAuthors(term)
    },
    registerMatomoEventForAuthorSuggestion(eventName) {
      registerMatomoEvent('Search', 'Author suggestions', eventName)
    },
  },
}
</script>
<template>
  <div v-if="suggestedAuthors.length" id="search-results--sugAuthor--panel">
    <h3 class="title">
      Suggested authors matching &quot;{{ query.replace(/['"]/g, '') }}&quot;
      with an ORCID
      <tooltip boundaries="#search-page-content">
        <i slot="trigger" class="far fa-question-circle" />
        Suggested authors have linked their articles in Europe PMC to their
        ORCID iD. Up to two suggested authors with a matching surname and
        initial are shown, ordered by the total number of publications they have
        in Europe PMC.
      </tooltip>
    </h3>
    <list :list="suggestedAuthors" separator-above separator-below>
      <div
        :key="item.orcidId"
        slot-scope="{ item }"
        class="suggested-author small"
      >
        <div>
          <action
            v-if="item.displayValue"
            :id="'search-results--sugAuthor--name-' + item.orcidId"
            :to="{
              name: 'search',
              query: { query: 'AUTHORID:' + item.orcidId.toUpperCase() },
            }"
            @click="registerMatomoEventForAuthorSuggestion('Author name')"
          >
            <i slot="icon" class="fas fa-search search-icon" />{{
              item.displayValue
            }}
          </action>
          <br />
          <span class="suggested-author-affiliation">
            {{
              item.affiliation ? item.affiliation : 'No affiliation detected'
            }}
          </span>
        </div>
        <div>
          {{
            item.totalPublications
              ? item.totalPublications.toLocaleString()
              : 0
          }}<br class="remove" />
          Publication{{ item.totalPublications !== '1' ? 's' : '' }}
        </div>
        <div>
          {{ item.citationCount ? item.citationCount.toLocaleString() : 0
          }}<br class="remove" />
          Citation{{ item.citationCount !== 1 ? 's' : '' }}
        </div>
        <div>
          <router-link
            :id="'search-results--sugAuthor--ViewLink-' + item.orcidId"
            :to="{
              name: 'authorProfile',
              params: { orcid: item.orcidId.toUpperCase() },
            }"
            @click="registerMatomoEventForAuthorSuggestion('Author profile')"
            >View profile</router-link
          >
        </div>
      </div>
    </list>
  </div>
</template>
<style lang="scss">
#search-results--sugAuthor--panel {
  .title {
    margin-top: 0;
    font-size: $base-unit * 4;
    line-height: $base-unit * 6;
  }
  li {
    margin: ($base-unit * 2) 0;
  }
  hr {
    margin-top: $base-unit * 2;
  }
  .suggested-author {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    div {
      flex-shrink: 0;
      padding: 0 ($base-unit * 2);
      text-align: right;
      &:first-child {
        text-align: left;
        width: 50%;
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
      .suggested-author-affiliation {
        padding-left: $base-unit * 5.5;
      }
    }
    @media screen and (max-width: $breakpoint-small) {
      flex-wrap: wrap;
      div:last-child {
        padding: 0;
        margin-top: $base-unit;
      }
    }
    @media screen and (max-width: $breakpoint-smallest) {
      div {
        padding: 0;
        text-align: left;
        margin: $base-unit 0;
        &:first-child {
          width: 100%;
        }
        .remove {
          display: none;
        }
      }
    }
  }
}
</style>
